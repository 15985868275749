import React, { useState, useRef } from 'react';

const Subheading: React.FC<{ title: string }> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const subheadingRef = useRef<HTMLDivElement>(null);

  return (
    <div id={title.replace(/\s+/g, '-')} ref={subheadingRef}>
      <h2 onClick={() => setIsOpen(!isOpen)}>{title}</h2>
      {isOpen && <div>{children}</div>}
    </div>
  );
};

export default Subheading;

/*
const Subheading: React.FC<{ title: string }> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div>
      <h2 onClick={() => setIsOpen(!isOpen)}>{title}</h2>
      {isOpen && <div>{children}</div>}
    </div>
  );
};

export default Subheading;
*/