import React from 'react';

const DocumentLink: React.FC<{ name: string; url: string }> = ({ name, url }) => {
  const fileName = decodeURIComponent(url.split('?')[0].split('/').pop() || '');
  return (
    <li>
      <a href={url} target="_blank" rel="noopener noreferrer">
        {fileName}
      </a>
    </li>
  );
};

export default DocumentLink;

/*
const DocumentLink: React.FC<{ name: string; url: string }> = ({ name, url }) => (
  <li>
    <a href={url} target="_blank" rel="noopener noreferrer">
      {name}
    </a>
  </li>
);

export default DocumentLink;
*/