import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Loader from 'rsuite/Loader';
import Message from 'rsuite/Message';
import { useToaster } from 'rsuite/esm/toaster';
import { AxiosError } from 'axios';
import { getHeaders } from '../../helpers/helper';
import axiosInstance from '../../shared/config/axiosInstance';
import QuickLinks from './QuickLinks';
import SearchBox from './SearchBox';
import Subheading from './Subheading';
import DocumentLink from './DocumentLink';
import FloatingButton from './FloatingButton';
import './MakeGuidePage.css'; // Import the CSS file

const MakeGuidePage: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const toaster = useToaster();
  const [blobs, setBlobs] = useState<{ [key: string]: { name: string; url: string }[] }>({});
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const parseBlobs = (blobs: string[]) => {
    const baseDocumentUrl = process.env.REACT_APP_BASE_DOCUMENT_URL || "";
    const groupedBlobs: { [key: string]: { name: string; url: string }[] } = {};

    blobs.forEach(blob => {
      const relativePath = blob.replace(baseDocumentUrl, "");
      const [folder, ...fileParts] = relativePath.split("/");
      const fileName = fileParts.join("/");

      if (!groupedBlobs[folder]) {
        groupedBlobs[folder] = [];
      }
      groupedBlobs[folder].push({ name: fileName, url: blob });
    });

    return groupedBlobs;
  };

  useEffect(() => {
    const fetchBlobs = async () => {
      setLoading(true);
      try {
        const headers = await getHeaders(getAccessTokenSilently);
        const response = await axiosInstance.get('/blob', { headers });
        const groupedBlobs = parseBlobs(response.data);
        setBlobs(groupedBlobs);
      } catch (error) {
        console.error('Error fetching blobs:', error);
        toaster.push(
          <Message showIcon type='error' closable>
            Error fetching blobs.
          </Message>,
          { placement: 'topEnd', duration: 3000 }
        );
      } finally {
        setLoading(false);
      }
    };

    fetchBlobs();
  }, [getAccessTokenSilently, toaster]);

  const handleSearch = (query: string) => {
    setSearchQuery(query.toLowerCase());
  };

  const filteredBlobs = Object.keys(blobs).reduce((acc, folder) => {
    const filteredDocs = blobs[folder].filter(doc => doc.name.toLowerCase().includes(searchQuery));
    if (filteredDocs.length > 0) {
      acc[folder] = filteredDocs;
    }
    return acc;
  }, {} as { [key: string]: { name: string; url: string }[] });

  /*
    <div className="nav-strip">
      <QuickLinks links={Object.keys(filteredBlobs)} />
      </div>
  */

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="ml-lg-5 mt-md-3 text-left">
      <h1>Make Guides</h1>
      <SearchBox
          className="search-box mt-md-1 mb-md-3" // Apply the search-box class
          onSearch={handleSearch}
        />
      {Object.keys(filteredBlobs).map(folder => (
        <Subheading key={folder} title={folder}>
          <ul>
            {filteredBlobs[folder].map((doc, index) => (
              <DocumentLink key={index} name={doc.name} url={doc.url} />
            ))}
          </ul>
        </Subheading>
      ))}
      <FloatingButton />
    </div>
  );
};

export default MakeGuidePage;

/* 
// components/MakeGuidePage.tsx
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getHeaders } from '../../helpers/helper';
import axiosInstance from '../../shared/config/axiosInstance';

const MakeGuidePage: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [blobs, setBlobs] = useState<string[]>([]);

  useEffect(() => {
    const fetchBlobs = async () => {
      try {
        const headers = await getHeaders(getAccessTokenSilently);
        const response = await axiosInstance.get('/blob', { headers });
        setBlobs(response.data);
      } catch (error) {
        console.error('Error fetching blobs:', error);
      }
    };

    fetchBlobs();
  }, [getAccessTokenSilently]);

  return (
    <div>
      <h1>Blobs</h1>
      <ul>
        {blobs.map((blob, index) => (
          <li key={index}>
            <a href={blob} target="_blank" rel="noopener noreferrer">
              {blob}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MakeGuidePage;
*/

/*import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getHeaders } from '../../helpers/helper';
import axiosInstance from '../../shared/config/axiosInstance';

const MakeGuidePage: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [blobs, setBlobs] = useState<string[]>([]);

  useEffect(() => {
    const fetchBlobs = async () => {
      try {
        const headers = await getHeaders(getAccessTokenSilently);
        const response = await axiosInstance.get('/blob', { headers });
        setBlobs(response.data);
      } catch (error) {
        console.error('Error fetching blobs:', error);
      }
    };

    fetchBlobs();
  }, [getAccessTokenSilently]);

  return (
    <div>
      <h1>Blobs</h1>
      <ul>
        {blobs.map((blob, index) => (
          <li key={index}>{blob}</li>
        ))}
      </ul>
    </div>
  );
};

export default MakeGuidePage;*/