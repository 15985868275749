import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { createBrowserHistory } from "history";
import { HashRouter, Switch } from "react-router-dom";
import "./App.css";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { Nav } from "./components/Nav";
import { Error } from "./components/Error";
import { Loading } from "./components/Loading";
import { Home } from "./components/Home";
import { AdminHome } from "./components/AdminHome";
import { Reports } from "./components/Report/Reports";
import { BuildByDateRangeReport } from "./components/Report/BuildByDateRangeReport";
import { DailyProductionReport } from "./components/Report/DailyProductionReport";
import { HighVarianceReport } from "./components/Report/HighVarianceReport";
import { Stores } from "./components/common/Stores";
import { FirdtProductProducedByStore } from "./components/Report/FirstProductProducedByStore";
import { StoreWithNoActuals } from "./components/Report/StoreWithNoActuals";
import { StoreWithNoBuildTo } from "./components/Report/StoreWithNoBuildTo";
import Production from "./components/Production/Production";
import TakeHomeSpecial from "./components/TakeHomeSpecials/TakeHomeSpecial";
import NewPlanning from "./components/Planning/NewPlanning";
import { useUserStores } from "./shared/hooks/useUserStores";
import Catering from "./components/Catering/Catering";
import PrepList from "./components/PrepList";
import AdminDashboard from "./components/AdminDashboard";
import MakeGuidePage from './components/MakeGuide/MakeGuidePage';

export const history = createBrowserHistory();

function App() {
  const { error } = useAuth0();

  const { isLoading } = useUserStores();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <HashRouter>
        <Nav>
          {error && <Error message={error.message} />}
          <Switch>
            <ProtectedRoute exact path="/" component={Home} />
            <ProtectedRoute path="/planning" component={NewPlanning} />
            <ProtectedRoute path="/production" component={Production} />
            <ProtectedRoute
              path="/takehomespecial"
              component={TakeHomeSpecial}
            />
            <ProtectedRoute path="/reports" component={Reports} />
            <ProtectedRoute
              exact
              path="/detailreport1"
              component={BuildByDateRangeReport}
            />
            <ProtectedRoute
              exact
              path="/detailreport2"
              component={DailyProductionReport}
            />
            <ProtectedRoute
              exact
              path="/detailreport3"
              component={HighVarianceReport}
            />
            <ProtectedRoute exact path="/stores" component={Stores} />
            <ProtectedRoute exact path="/HeadOffice" component={AdminHome} />
            <ProtectedRoute
              exact
              path="/HeadOffice/ProductProducedByStore"
              component={FirdtProductProducedByStore}
            />
            <ProtectedRoute
              exact
              path="/HeadOffice/StoreWithNoBuildTo"
              component={StoreWithNoBuildTo}
            />
            <ProtectedRoute
              exact
              path="/HeadOffice/StoreWithNoActuals"
              component={StoreWithNoActuals}
            />
            <ProtectedRoute
              exact
              path="/catering"
              component={Catering}
            />
            <ProtectedRoute
              exact
              path="/prep"
              component={PrepList}
            />
            <ProtectedRoute
              path="/admin"
              component={AdminDashboard}
            />
            <ProtectedRoute path="/makeguide" component={MakeGuidePage} />
          </Switch>
        </Nav>
      </HashRouter>
    </>
  );
}

export default App;
