import React, { useState } from 'react';

interface SearchBoxProps {
  onSearch: (query: string) => void;
  className?: string; // Add className prop
}

const SearchBox: React.FC<SearchBoxProps> = ({ onSearch, className }) => {
  const [query, setQuery] = useState("");

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    onSearch(e.target.value);
  };

  return (
    <input
      type="text"
      value={query}
      onChange={handleSearch}
      placeholder="Search documents..."
      className={className} // Apply className prop
    />
  );
};

export default SearchBox;

/*
import React, { useState } from 'react';
export {};

const SearchBox: React.FC<{ onSearch: (query: string) => void }> = ({ onSearch }) => {
  const [query, setQuery] = useState("");

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    onSearch(e.target.value);
  };

  return (
    <input
      type="text"
      value={query}
      onChange={handleSearch}
      placeholder="Search documents..."
    />
  );
};

export default SearchBox;
*/