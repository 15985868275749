import React, { useEffect, useState, ReactNode } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import img from "../assets/images/Assets/home.png";
import dash from "../assets/images/icons/store.svg";
import log from "../assets/images/logout.png";
import { getUserStoresFromLocalStorage } from "../helpers/localStorageHelper";
import RoomServiceIcon from "@material-ui/icons/RoomService";
import { FaUserCog } from "react-icons/fa";
import { useCateringOrderCount } from "../shared/hooks/useCateringOrderCount";
import {Loading} from './Loading'
import { isAdmin } from "../helpers/helper";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
const drawerWidth = 30;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    content: {
      flexGrow: 1,
      paddingLeft: theme.spacing(3),
      paddingTop: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  })
);

type Props = {
  children: ReactNode
};

export const Nav: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  const toggler = false;
  const [open, setOpen] = React.useState(true);
  const {data: orderCount, isLoading} = useCateringOrderCount();
  
  useEffect(() => {
    if (window.innerWidth < 1025) {
      setOpen(false);
    }
  }, [window.innerWidth]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(toggler);
  };
  const { isAuthenticated, user, loginWithRedirect, logout } = useAuth0();

  const history = useHistory();
  const [pathname, setPathname] = useState(() => history.location.pathname);

  useEffect(() => {
    return history.listen(({ pathname }) => setPathname(pathname));
  }, [history]);

  const currentPath = useLocation().pathname;
  const userStores = getUserStoresFromLocalStorage();

  if (isLoading) {
    return <Loading />
  }

  function LoggedNav() {
    return (
      <>
        <div className={classes.root}>
          <CssBaseline />
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <div className="d-md-block d-xl-none">
              <IconButton
                onClick={handleDrawerClose}
                style={{ display: open ? "block" : "none" }}
              >
                <ChevronLeftIcon />
              </IconButton>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <MenuIcon />
              </IconButton>
            </div>
            <Divider />
            <List className="pt-0 border-0">
              {currentPath === "/" ? (
                <Link
                  to="/"
                  className={`text-dark bg-home nav-item nav-link${
                    pathname === "/" ? " active" : ""
                  }`}
                >
                  <div className="d-grid">
                    <img className="mx-auto" src={img} alt="home" />
                    <p className="text-center mb-0 mt-1">Home</p>
                  </div>
                </Link>
              ) : currentPath.includes("/detailreport") ? (
                <Link
                  to="/reports"
                  className={`text-dark bg-home nav-item nav-link${
                    pathname === "/" ? " active" : ""
                  }`}
                >
                  <div className="d-grid">
                    <i
                      className="fa fa-lg mx-auto fa-long-arrow-left my-2"
                      aria-hidden="true"
                    ></i>
                    <p className="text-center mb-0 mt-1">Back</p>
                  </div>
                </Link>
              ) : currentPath.includes("/HeadOffice/") ? (
                <Link
                  to="/HeadOffice"
                  className={`text-dark bg-home nav-item nav-link${
                    pathname === "/" ? " active" : ""
                  }`}
                >
                  <div className="d-grid">
                    <i
                      className="fa fa-lg mx-auto fa-long-arrow-left my-2"
                      aria-hidden="true"
                    ></i>
                    <p className="text-center mb-0 mt-1">Back</p>
                  </div>
                </Link>
              ) : (
                <Link
                  to="/"
                  className={`text-dark bg-home nav-item nav-link${
                    pathname === "/" ? " active" : ""
                  }`}
                >
                  <div className="d-grid">
                    <i
                      className="fa fa-lg mx-auto fa-long-arrow-left my-2"
                      aria-hidden="true"
                    ></i>
                    <p className="text-center mb-0 mt-1">Back</p>
                  </div>
                </Link>
              )}
            </List>
            <List
              className={userStores.length > 1 ? "my-auto" : ""}
              id="storeMenu"
              // style={{ display: userStores.length > 1 ? "block" : "none" }}
            >
              <Link to="/stores">
                <div className="d-grid">
                  <img className="mx-auto" src={dash} alt="home" style={{width: '45px', height: '45px'}} />
                  <p className="mb-0 text-center nav-org" style={{marginTop: '12px'}}>Stores</p>
                </div>
              </Link>
              <Link to="/catering">
                <div className="d-grid position-relative mt-4">
                  <RoomServiceIcon
                    className="nav-org"
                    style={{ margin: "0 auto", fontSize: "3rem" }}
                  />
                  <span
                    className="position-absolute"
                    style={{
                      // padding: "0 0 4px 0",
                      color: "#fff",
                      background: "#e41e3f",
                      fontWeight: "bold",
                      width: '20px', 
                      height: '20px', 
                      borderRadius: '50%',
                      right: '16px',
                      top: '8px',
                      textAlign: 'center'
                    }}
                  >
                    {orderCount}
                  </span>
                  <p className="mb-0 text-center nav-org">Catering</p>
                </div>
              </Link>
              <Link to="/makeguide">
                <div className="d-grid mt-4">
                  <HiOutlineClipboardDocumentList
                    className="nav-org"
                    style={{ margin: "0 auto", fontSize: "3rem" }}
                  />
                  <p className="mb-0 text-center nav-org">Make Guides</p>
                </div>
              </Link>
              {isAdmin() && <Link to="/admin">
                <div className="d-grid mt-4">
                  <FaUserCog
                    className="nav-org"
                    style={{ margin: "0 auto", fontSize: "3rem" }}
                  />
                  <p className="mb-0 text-center nav-org">Admin</p>
                </div>
              </Link>}
            </List>
            <List className="mt-auto">
              <div className="d-grid mb-3">
                <span className="dot"></span>
                <img
                  src={user && user.picture}
                  className="mx-auto rounded-circle profile-img b-org p-2"
                  alt={user && user.name}
                />
                <p className="grey text-wrap text-center mx-auto mb-0 mt-1">
                  {user && user.name}
                </p>
              </div>
              {/* <Link
              to="/"
              className={`text-dark nav-item nav-link${
                pathname === "/" ? " active" : ""
              }`}
            >  */}
              <div className="d-grid">
                <img
                  className="mx-auto invert-5"
                  src={log}
                  style={{ height: 21 }}
                  alt="home"
                />
                <button
                  className="mt-1 grey bg-white border-0  text-center"
                  id="logout"
                  onClick={() => {
                    localStorage.clear();
                    logout({ logoutParams: { returnTo: window.location.origin }});
                  }}
                >
                  logout
                </button>
              </div>
              {/* </Link> */}
            </List>
          </Drawer>
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: open,
            })}
          >
            <div>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <MenuIcon />
              </IconButton>
              {children}
            </div>
          </main>
        </div>
      </>
    );
  }

  function NotLoggedNav() {
    return (
      <>
        <nav className="navbar row">
          <div className="container-fluid mt-2">
            <div className="col"></div>
            <div>
              <button
                className="btn btn-primary"
                id="login"
                onClick={() => loginWithRedirect()}
              >
                login
              </button>
            </div>
          </div>
        </nav>
      </>
    );
  }

  function GetNavBar() {
    if (isAuthenticated) {
      const namespace = "https://sohq/roles";
      return <LoggedNav />;
    } else {
      return <LoggedNav />;
    }
  }

  return <GetNavBar />;
};
