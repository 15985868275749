import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import {
  addWeeks,
  format,
  getISOWeek,
  getISOWeekYear,
  startOfISOWeek,
} from "date-fns";
import { useState } from "react";
import { getHeaders } from "../../../helpers/helper";
import axiosInstance from "../../../shared/config/axiosInstance";
import { ProductionWeeklyPlan } from "../../../shared/models/ProductionPlanning";
import { QueryKeys } from "../../../shared/react-query/queryKeys";

async function fetchProductionPlanning(
  planWeek: number,
  planYear: number,
  getAccessTokenSilently: any
): Promise<ProductionWeeklyPlan> {
  // planWeek=37&planYear=2022
  const headers = await getHeaders(getAccessTokenSilently);
  const { data } = await axiosInstance.get("/productionplanning", {
    params: {
      planWeek,
      planYear,
    },
    headers: headers,
  });

  return data;
}

export function useProductionPlanning() {
  const [weekStartDate, setWeekStartDate] = useState(
    startOfISOWeek(new Date())
  );
  const { getAccessTokenSilently } = useAuth0();

  const changeWeek = (change: 1 | -1, cb?: Function) => {
    let newStartDate: Date;
    switch (change) {
      case 1:
        newStartDate = addWeeks(weekStartDate, 1);
        setWeekStartDate(newStartDate);
        break;
      case -1:
        newStartDate = addWeeks(weekStartDate, -1);
        setWeekStartDate(newStartDate);
        break;
    }

    if (cb) {
      cb();
    }
  };

  const { data: productionPlanningData, isLoading: isLoadingPlanningData } = useQuery(
    {
      queryKey: [QueryKeys.PRODUCTION_PLANNING, format(weekStartDate, "yyyy/MM/dd")],
      queryFn: () => fetchProductionPlanning(getISOWeek(weekStartDate), getISOWeekYear(weekStartDate), getAccessTokenSilently),
      refetchOnWindowFocus: true
    }
  )

  return {
    weekStartDate,
    changeWeek,
    productionPlanningData,
    isLoadingPlanningData
  };
}
