import React from 'react';
export {};

const FloatingButton: React.FC = () => (
  <button
    style={{
      position: "fixed",
      bottom: "20px",
      right: "20px",
      padding: "10px",
      borderRadius: "50%",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      cursor: "pointer"
    }}
    onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
  >
    ↑
  </button>
);

export default FloatingButton;